import type { RemoteOperationParams } from '@farfetched/core'
import { createMutation } from '@farfetched/core'
import { invoke } from '@withease/factories'
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'
import { createAction } from 'effector-action'
import { produce } from 'immer'
import { not, snapshot } from 'patronum'
import { mapValues } from 'remeda'
import { createSalesServiceEffect } from '~/api/sales'
import { createDisclosureAtom } from '~/shared/lib/factories/create-disclosure'
import { createStateAtom } from '~/shared/lib/factories/create-state'
import { $channelId, channelChanged } from '../../../channel.model'
import type { SaleError, SaleStatus } from '../../types'

export const BASE_ROWS_COUNT = 25

type PreSaleDTO = {
  user_id: string
  name?: string | null
  product?: string | null
  action_date?: string | null
  purchase_amount?: number | null
  status: SaleStatus
}

export type PreSaleDTOTuple = [
  string | null | undefined,
  string | null | undefined,
  string | null | undefined,
  string | null | undefined,
  number | null | undefined,
]

const saleStatuses = ['funnel', 'lead', 'sale'] as const

export const modalDisclosureAtom = invoke(() =>
  createDisclosureAtom({ initial: false }),
)

export const createRowPlaceholder = () =>
  ['', '', '', '', 0] satisfies PreSaleDTOTuple

const $spreadSheetDataKv = createStore<Record<SaleStatus, PreSaleDTOTuple[]>>({
  lead: Array.from({ length: BASE_ROWS_COUNT }).map(() =>
    createRowPlaceholder(),
  ),
  funnel: Array.from({ length: BASE_ROWS_COUNT }).map(() =>
    createRowPlaceholder(),
  ),
  sale: Array.from({ length: BASE_ROWS_COUNT }).map(() =>
    createRowPlaceholder(),
  ),
})

type UpdateSpreadsheetDataActionPayload = {
  key: SaleStatus
  value: PreSaleDTOTuple[]
}

export const spreadSheetDataUpdated = createAction({
  target: $spreadSheetDataKv,
  fn: (spreadSheetData, payload: UpdateSpreadsheetDataActionPayload) => {
    spreadSheetData(currentData =>
      produce(currentData, draft => {
        draft[payload.key] = payload.value
      }),
    )
  },
})

export const makeSpreadSheetDataSnapshot = createEvent()

export const $spreadSheetDataSnapshot = snapshot({
  source: $spreadSheetDataKv,
  clock: makeSpreadSheetDataSnapshot,
})

export const saleStatusLabelsMap = {
  funnel: 'Воронки',
  lead: 'Лиды',
  sale: 'Продажи',
} satisfies Record<SaleStatus, string>

export const saleStatusOptions = saleStatuses.map(sectionKey => ({
  label: saleStatusLabelsMap[sectionKey],
  value: sectionKey,
}))

export const $currentSaleStatusTab = createStore<SaleStatus>('funnel')

export const currentSectionTabChanged = createAction({
  target: $currentSaleStatusTab,
  fn: (salesStatusTab, tabKey: SaleStatus) => {
    salesStatusTab(tabKey)
  },
})

export const createSaleReportMutation = createMutation({
  effect: createSalesServiceEffect({
    method: 'post',
    url: '/sales/create',
  }),
})

export const nameAtom = invoke(() => createStateAtom({ defaultState: '' }))

export const $salesFilesKv = createStore<Record<SaleStatus, File[]>>({
  funnel: [],
  lead: [],
  sale: [],
})
export const salesFilesSelected = createAction({
  target: $salesFilesKv,
  fn: (kv, payload: { key: SaleStatus; value: File[] }) => {
    kv(currentData =>
      produce(currentData, draft => {
        draft[payload.key] = payload.value
      }),
    )
  },
})

export const $fillStatus = combine(
  [$spreadSheetDataKv, $salesFilesKv],
  ([spreadsheet, files]) => {
    return mapValues(spreadsheet, (values, key) => {
      return (
        values.filter(row => row[0]?.trim()?.length ?? 0 > 0).length > 0 ||
        files[key].length > 0
      )
    })
  },
)

export const submit = createAction({
  target: createSaleReportMutation.start,
  source: {
    channelId: $channelId,
    name: nameAtom.$value,
    spreadsheetDataKv: $spreadSheetDataKv,
    salesFilesKv: $salesFilesKv,
  },
  fn: (start, { channelId, name, salesFilesKv, spreadsheetDataKv }) => {
    const formData = new FormData()

    Object.entries(salesFilesKv).forEach(([key, files]) => {
      if (files.length > 0) {
        files.forEach(file => {
          formData.append(`${key}_file`, file)
        })
      }
    })

    const json = Object.entries(spreadsheetDataKv)
      .map(([key, rows]) => {
        return rows
          .filter(row => (row[0]?.trim()?.length ?? 0) > 0)
          .map(
            ([user_id, name, product, action_date, purchase_amount]) =>
              ({
                user_id: user_id!,
                name: name || null,
                product: product || null,
                action_date: action_date
                  ? new Date(action_date).toJSON()
                  : null,
                purchase_amount: purchase_amount ?? 0,
                status: key as SaleStatus,
              }) satisfies PreSaleDTO,
          )
      })
      .flat()

    if (json.length > 0) {
      formData.append('json_data', JSON.stringify(json))
    }

    const opts = {
      params: {
        query: {
          channel_id: channelId!,
          name: name || `сверка_${new Date().toLocaleString()}`,
          budget: 0,
        },
      },
      body: formData as unknown as undefined,
    } satisfies RemoteOperationParams<typeof createSaleReportMutation>

    start(opts)
  },
})

sample({
  clock: [channelChanged, createSaleReportMutation.finished.success],
  target: [modalDisclosureAtom.close],
})

export const $validationMessages = combine(
  [nameAtom.$value, $salesFilesKv, $spreadSheetDataKv],
  ([name, filesKv, spreadSheetData]) => {
    const messages: string[] = []

    const isValidName = name.trim().length > 0

    if (!isValidName) {
      messages.push('Введите название сверки')
    }

    const isValidFiles =
      Object.values(filesKv).some(files => files.length > 0) ||
      Object.values(spreadSheetData).some(rows =>
        rows.some(row => row[0]?.trim()?.length ?? 0 > 0),
      )

    if (!isValidFiles) {
      messages.push(
        'Необходимо выбрать хотя бы один файл или заполнить таблицу',
      )
    }

    return messages
  },
)

sample({
  clock: modalDisclosureAtom.closed,
  source: {
    isPending: createSaleReportMutation.$pending,
  },
  filter({ isPending }) {
    return !isPending
  },
  target: [
    createSaleReportMutation.reset,
    nameAtom.reset,
    $salesFilesKv.reinit,
    $spreadSheetDataKv.reinit,
    $spreadSheetDataSnapshot.reinit,
  ] as const,
})

const DEFAULT_API_ERROR_MESSAGE = 'Что-то пошло не так'

const parseApiErrorsFx = createEffect({
  handler: async (error: unknown) => {
    if (error instanceof Error) {
      return error.message || `${DEFAULT_API_ERROR_MESSAGE} [Код: 0]`
    } else if (error instanceof Response) {
      const errorResponse = error.clone()

      const errorBody = await errorResponse
        .text()
        .then(errorText => JSON.parse(errorText))
        .catch(err =>
          err instanceof Error
            ? err.message
            : `${DEFAULT_API_ERROR_MESSAGE} [Код: 1]`,
        )

      if (
        typeof errorBody === 'object' &&
        errorBody !== null &&
        'detail' in errorBody
      ) {
        if (typeof errorBody.detail === 'string') {
          return `[4] ${errorBody.detail}`
        } else if (Array.isArray(errorBody.detail)) {
          return (errorBody.detail as { msg: string }[])
            .map(err => err.msg)
            .join(', ')
        } else if (
          typeof errorBody.detail === 'object' &&
          errorBody.detail !== null &&
          'errors' in errorBody.detail
        ) {
          return (errorBody.detail.errors as SaleError[]).reduce(
            (kv, { filename, row, error }) => {
              if (kv[filename]) {
                kv[filename].push(`[Строка:${row}]: ${error}`)
              } else {
                kv[filename] = [`[Строка:${row}]: ${error}`]
              }
              return kv
            },
            {} as Record<string, string[]>,
          )
        } else {
          return `${DEFAULT_API_ERROR_MESSAGE} [Код: 2]`
        }
      } else {
        return typeof errorBody === 'string'
          ? errorBody
          : `${DEFAULT_API_ERROR_MESSAGE} [Код: 3]`
      }
    } else {
      return `${DEFAULT_API_ERROR_MESSAGE} [Код: 4]`
    }
  },
})

sample({
  clock: createSaleReportMutation.finished.failure,
  fn({ error }) {
    return error
  },
  target: parseApiErrorsFx,
})

export const $apiErrors = createStore<string | Record<string, string[]> | null>(
  null,
)
export const errorDialogDisclosureAtom = invoke(() => createDisclosureAtom())

sample({
  clock: parseApiErrorsFx.doneData,
  target: [$apiErrors, errorDialogDisclosureAtom.open] as const,
})

const $isFilledForm = combine(
  [$fillStatus, nameAtom.$value],
  ([statusKv, name]) => {
    return (
      Object.values(statusKv).some(status => status) || name.trim().length > 0
    )
  },
)

export const closeDialog = createEvent()

sample({
  clock: closeDialog,
  filter: not($isFilledForm),
  target: modalDisclosureAtom.close,
})

export const confirmDialogDisclosureAtom = invoke(() => createDisclosureAtom())

sample({
  clock: closeDialog,
  filter: $isFilledForm,
  target: confirmDialogDisclosureAtom.open,
})

export const confirmClicked = createEvent()

sample({
  clock: confirmClicked,
  target: modalDisclosureAtom.close,
})
