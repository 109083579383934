import { $channelId, $isChannelLoaded } from '@channel/channel.model'
import { invoke } from '@withease/factories'
import { sample } from 'effector'
import { createAction } from 'effector-action'
import { createDateTimeRangeModel } from '~/shared/lib/factories/date-range/create-date-range-model'
import { createInviteLinksWidgetModel } from '../../../widgets/analytics_filters/invite_links_filter/factory'
import { $selectedSalesReport } from '../reports_list/model'

export const reportInviteLinksFilterModel = invoke(() =>
  createInviteLinksWidgetModel({
    channelId: $channelId,
    isEnabled: $isChannelLoaded,
  }),
)

export const datetimeRangeFilterModel = invoke(() =>
  createDateTimeRangeModel({
    initialStartDate: null,
    initialEndDate: null,
    preset: null,
  }),
)

sample({
  clock: $selectedSalesReport,
  filter: report => report === null,
  target: [
    reportInviteLinksFilterModel.resetAll,
    datetimeRangeFilterModel.reset,
  ] as const,
})

export const resetFilters = createAction({
  target: {
    resetInviteLinksFilter: reportInviteLinksFilterModel.resetAll,
    resetDateRangeFilter: datetimeRangeFilterModel.reset,
  },
  fn: target => {
    target.resetDateRangeFilter()
    target.resetInviteLinksFilter()
  },
})
