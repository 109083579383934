import { onAbort } from '@farfetched/core'
import { createEffect } from 'effector'
import type { FetchResponse, MaybeOptionalInit } from 'openapi-fetch'
import createClient from 'openapi-fetch'
import type {
  HttpMethod,
  MediaType,
  PathsWithMethod,
} from 'openapi-typescript-helpers'
import { HOST } from './config'
import { authFetchFx } from './fetcher'
import type * as SalesServiceTypes from './sales_service.generated'

export type SalesServiceComponents<
  T extends keyof SalesServiceTypes.components['schemas'],
> = SalesServiceTypes.components['schemas'][T]

const salesServiceClient = createClient<SalesServiceTypes.paths>({
  baseUrl: `${HOST}/api/proxy/sales-service`,
  fetch: authFetchFx,
})

export const createSalesServiceEffect = <
  Media extends MediaType,
  Method extends HttpMethod,
  Path extends PathsWithMethod<SalesServiceTypes.paths, Method>,
  Init extends MaybeOptionalInit<SalesServiceTypes.paths[Path], Method>,
  Response extends Required<
    FetchResponse<SalesServiceTypes.paths[Path][Method], Init, Media>
  >,
>({
  method,
  url,
}: {
  method: Method
  url: Path
}) =>
  createEffect(async (init: Init) => {
    const abortController = new AbortController()

    onAbort(() => {
      abortController.abort()
    })

    if (init) {
      init.signal = abortController.signal
    }

    const METHOD = method.toUpperCase() as keyof typeof salesServiceClient

    const fn = salesServiceClient[METHOD] as unknown as (
      url: Path,
      init: Init,
    ) => Promise<Response>

    const { error, data } = await fn(url, init)

    if (error) {
      throw error
    }

    return data
  })
